export default function makeFetchExternalAutocomplete(getGoogleAutocomplete) {
    if (typeof getGoogleAutocomplete !== 'function') {
        throw new TypeError('callback za dohvaćanje google rezultata za autocomplete nije zadan');
    }
    return fetchExternalAutocomplete.bind(null, getGoogleAutocomplete);
}

function fetchExternalAutocomplete(getGoogleAutocomplete, term, onlyCroatia = true) {
    const googleAutocompleteService = getGoogleAutocomplete();

    if (!googleAutocompleteService) {
        return Promise.resolve([]);
    }

    return new Promise((resolve) => {
        googleAutocompleteService.getPlacePredictions({
            input: term,
            bounds: onlyCroatia ? { south: 42.38, east: 19.45, north: 46.55, west: 13.49 } : null,
            types: ['geocode'],
            componentRestrictions: onlyCroatia ? { country: 'hr' } : null,
        }, (googlePlacePredictions) => {
            if (!googlePlacePredictions) {
                resolve([]);
                return;
            }
            resolve(reformatGooglePredictions(googlePlacePredictions));
        });
    });
}

function reformatGooglePredictions(googlePlacePredictions) {
    return googlePlacePredictions.map((googleResult) => ({
        id: `google_${googleResult.place_id}`,
        value: googleResult.structured_formatting.main_text,
        description: googleResult.description,
    }));
}
