/**
 * Modul za dohvaćanje places autocomplete rezultata za naš custom autocomplete widget
 *
 * Postavlja zajedničke dependency-je fetchPlaces funkcije i vraća istu za korištenje.
 *
 * @param {function} jQueryPost $.post funkcija
 * @param {string} autocompleteUrl url do Adriatic.hr akcije koja vraća autocomplete podatke za Adriatic.hr mjesta
 * @param {function} externalAutocomplete callback kojim se dohvaćaju autocomplete rezultati iz eksternog servisa
 * @param {function} formatAutocompleteResults callback kojim se formatiraju rezultati dobijeni iz eksternog servisa
 *
 * @return {function} funkcija fetchPlaces() konfigurirana sa gore navedenim parametrima
 */
export default function configureFetchPlaces(
    jQueryPost,
    autocompleteUrl,
    externalAutocomplete,
    formatAutocompleteResults,
) {
    let ajaxRequest = null;

    if (process.env.NODE_ENV !== 'production') {
        if (typeof jQueryPost !== 'function') {
            throw new TypeError('jQuery $.post funkcija treba biti predana kao prvi argument');
        }

        if (!autocompleteUrl) {
            throw new TypeError('autocompleteUrl za dohvaćanje Adriatic.hr lokacija treba biti zadan');
        }

        if (typeof externalAutocomplete !== 'function') {
            throw new TypeError('callback za dohvaćanje vanjskih autocomplete rezultata nije zadan');
        }

        if (typeof formatAutocompleteResults !== 'function') {
            throw new TypeError('formatAutocompleteResults treba biti zadan');
        }
    }

    return fetchPlaces;

    /**
     * Dohvaća autocomplete podatke za zadani request i poziva response sa istima kao jedinim parametrom.
     *
     * Može se koristiti u našem $.placescomplete pluginu (kao source).
     *
     * @see http://api.jqueryui.com/autocomplete/#option-source
     *      *Function* varijaciju za dokumentaciju ulaznih parametara
     *
     * @param {object} request
     * @param {function} response
     *
     * @return {mixed} ako postoje adriatic rezultati, vraćamo njih, a u suprotnom vraćamo rezultate upita na eksterni
     * servis. Format je jednak povratnoj vrijednosti callbacka za dohvat rezultata(i adriatic i eksternih);
     */
    function fetchPlaces(request, response) {
        if (ajaxRequest !== null) {
            ajaxRequest.abort();
        }
        ajaxRequest = jQueryPost(autocompleteUrl, { term: request.term });
        ajaxRequest.then(fetchExternalPlaces);

        function fetchExternalPlaces(adriaticAutocompleteData) {
            if (adriaticAutocompleteData.length !== 0) {
                response(adriaticAutocompleteData);
                return;
            }

            externalAutocomplete(request.term).then((predictions) => {
                if (predictions.length === 0) {
                    response(adriaticAutocompleteData);
                    return;
                }
                response(formatAutocompleteResults(predictions));
            });
        }
    }
}
