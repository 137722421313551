if (typeof lang_culture != 'undefined') {
    $.datepicker.setDefaults($.datepicker.regional[lang_culture]);
}
$.ajaxSetup({cache: true});
$.extend($.fn.jScrollPane.defaults, { showArrows: true, scrollbarWidth: 10, arrowScrollOnHover: true, hideFocus: true,
    arrowSize: 15, mouseWheelSpeed: 50, verticalGutter: 0 });

// facebook je od neke verzije dodaje fragment _=_ na URL nakon callbacka
// uklanjamo taj dodatak
if (window.location.hash && window.location.hash == '#_=_') {
    window.location.hash = '';
}

var prettyPhotoOptions = {
    'changepicturecallback': function(){$(document).off('mousemove'); $('.mousetrack').hide();},
    'deeplinking': false,
    'theme' : 'serviceLightbox',
    'default_width' : 940,
    'default_height' : 500,
    'allow_resize': false,
    'markup' : '<div class="pp_pic_holder"> \
        <div class="ppt">&nbsp;</div> \
        <div class="pp_content_container"> \
            <div class="pp_content"> \
                <div class="pp_loaderIcon"></div> \
                <div class="pp_fade"> \
                    <a href="#" class="pp_expand" title="Expand the image">Expand</a> \
                    <div class="pp_hoverContainer"> \
                        <a class="pp_next" href="#">next</a> \
                        <a class="pp_previous" href="#">previous</a> \
                    </div> \
                    <div id="pp_full_res"></div> \
                    <div class="pp_details clearfix"> \
                        <a class="pp_close" href="#">Close</a> \
                        <p class="pp_description"></p> \
                        <div class="pp_nav"> \
                            <a href="#" class="pp_arrow_previous">Previous</a> \
                            <p class="currentTextHolder">0/0</p> \
                            <a href="#" class="pp_arrow_next">Next</a> \
                        </div> \
                    </div> \
                </div> \
            </div> \
        </div> \
    </div> \
    <div class="pp_overlay"></div>'
};

function initPrettyPhoto() {
    $("a[rel^='lightbox']").prettyPhoto(prettyPhotoOptions);
}

function triggerOnload(){

    initPrettyPhoto();

    $(".tooltip").tooltip({
        position: {
            using: function (position) {
                if ($(".ui-tooltip").length > 1) {
                    $(".ui-tooltip")[0].remove();
                }

                $(this).css(position);
            },
        },
    });

    // Custom tooltip koji se otvara na click

    $('body').append(
     '<div id="tooltip_static_container" class="ui-tooltip ui-widget ui-corner-all ui-widget-content" style="max-width: 400px; display:none;">'+
     '<div id="tooltip_static_content" class="ui-tooltip-content"></div></div>');

    $(document).on('click', '.tooltip_static', function() {
        return false;
    });

    $(document).on('mouseenter touchstart', '.tooltip_static', function(e){
        e.preventDefault();
        var that = $(this);

        if (that.attr('title') !== '') {
            that.attr('data-tooltip', that.attr('title'));
            that.attr('title', '');
        }

        var tooltip = $('#tooltip_static_container'),
            tooltipContent = tooltip.find('#tooltip_static_content'),
            tooltipText = that.data('tooltip');

        if(tooltipContent.html() == tooltipText) {
            that.blur();
        } else {
            var rightFreeSpace = 0,
                tooltipPosition = {},
                elPosition = that.offset();

            that.focus();
            tooltipContent.html(tooltipText);

            rightFreeSpace = $(window).width() - elPosition.left;

            if (rightFreeSpace < 240) {
                tooltip.css({'left':0, 'visibility':'hidden', 'display': 'block'});
                tooltipPosition = {
                    'left': elPosition.left - that.width() - tooltip.width(),
                    'top': elPosition.top + 5,
                    'visibility': 'visible'
                };
            } else {
                tooltipPosition = {
                    'left': elPosition.left + that.width() + 5,
                    'top': elPosition.top + 5,
                    'display': 'block'
                };
            }

            tooltip.css(tooltipPosition);
        }
    }).on('mouseleave touchend', '.tooltip_static', function(){
        $('#tooltip_static_content').html('');
        $('#tooltip_static_container').hide();
    });

    // event tracking
    $(document).on('click submit', '[data-tracking-category]', function(e){
        onClickSendGaTracker(this, e);
    });
}

function onClickSendGaTracker(element, event) {
    if(event.type == 'click' && element.tagName.toLowerCase() == 'form') {
        // do not track clicks inside form
    }
    else if(event.type == 'click' && $(element).attr('data-donot-track')){
        // do not track clicks with data-donot-track = true
    }
    else {
        var label = $(element).data('tracking-label'),
            value = $(element).data('tracking-value');
        if(label == undefined) label = '';
        setGaqPush($(element).data('tracking-category'), $(element).data('tracking-action'), label, value);
    }
}

$('.grayedOutSelect').change(function() {
    this.style.color = "black";
});

export {
    prettyPhotoOptions,
    initPrettyPhoto,
    triggerOnload,
}
