/* global google */

/**
 * Google mape je potrebno uključiti na ovaj način jer ovako možemo src dodati pod <head> html tag. To je bitno jer se u
 * slučaju učitavanja stranice preko pjaxa google maps API neće nepotrebno učitavati više puta (što može dovesti do
 * grešaka).
 */
function includeGoogleMapsAsynchronously(googleMapsApiEndpoint) {
    const googleMapsApiElement = document.getElementById('googleMapsApi');
    if (googleMapsApiElement) {
        return;
    }

    const googleMapsAPI = document.createElement('script');
    googleMapsAPI.setAttribute('id', 'googleMapsApi');
    googleMapsAPI.setAttribute('async', true);
    googleMapsAPI.setAttribute('defer', true);
    googleMapsAPI.setAttribute('src', googleMapsApiEndpoint);

    document.getElementsByTagName('head')[0].appendChild(googleMapsAPI);
}

function areGoogleMapsAlreadyLoaded() {
    return typeof google === 'object' && typeof google.maps === 'object';
}

export {
    includeGoogleMapsAsynchronously,
    areGoogleMapsAlreadyLoaded,
};
