/* global ga */
import openModal from '@PROJECT_ROOT/assets/shared/js/modalBox';
import { isFrame, isHtml } from '@PROJECT_ROOT/assets/shared/js/isPartnerService';

export default function initializeNewsletterModal(state) {
    return initializeNewsletterModalUnconfigured(document, $, state);
}

export function initializeNewsletterModalUnconfigured(document, $, state) {
    if (isFrame() || isHtml()) {
        return;
    }

    // Ne prikazujemo newsletter popup za URL-ove koji sadržavaju /reservation/ putanju
    if (urlContainReservationPath()) {
        return;
    }

    let closeModalBox = null;
    const newsletterForm = document.getElementById('newsletterForm');
    const emailElement = document.getElementById('newsletterEmail');

    if (emailElement === null || newsletterForm === null) {
        return;
    }

    setupCookies();

    function showNewsletterModalBoxEvent(event) {
        showNewsletterModalBox(event, state.isSubscribedUrl);
    }

    // Prikazuje newsletter popup
    if (!$.cookie('popup-shown')) {
        // Koristimo "mouseout" jer "mouseleave" nije podržan u IE
        document.addEventListener('mouseout', showNewsletterModalBoxEvent);
    }

    // Uklanja "errorField" klasu s email input polja
    emailElement.addEventListener('click', () => {
        emailElement.classList.remove('errorField');
    });

    // Submitanje forme
    newsletterForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const postUrl = newsletterForm.getAttribute('action');
        const data = $('#newsletterForm').serialize();

        $.post(postUrl, data)
            .then((response) => {
                if (response !== true) { // greška
                    showErrorMessage(response.errors.email, emailElement);
                } else { // uspijeh i kraj
                    removeFormAndShowSuccessMessage(state.newsletterSuccessText, emailElement);
                    ga('send', 'event', 'Naslovnica', 'Newsletter', 'Popup send'); // email submittan
                    dontPopUpFor(90);
                    closeModalBoxAfter3Seconds();
                }
            });
    });

    function setupCookies() {
        // Za korisnike koji su se subscribali na newsletter ili su već jednom odbili,
        // za njih se neće prikazati newsletter popup
        // Takva stanja pamtimo 3 mjeseca u cookie popup-shown.
        if ($.cookie('popup-shown')) {
            return;
        }

        // ako nije već postavljen, postavi jednominutni indikator starosti sesije
        // s ciljem ne prikazivanja newsletter popupa korisnicima koji su se na stranici zadržali manje od 1 min
        if (!$.cookie('minute_incator_set')) {
            $.cookie('minute_indicator', 'true', {
                // otprilike 1 minuta u danima
                expires: 0.0007,
                path: '/',
            });
            $.cookie('minute_incator_set', 'true', {
                // 180 minuta u danima
                expires: 0.125,
                path: '/',
            });
        }
    }

    function showNewsletterModalBox(event, url) {
        // ovime smo osigurali da se popup otvori samo jednom čak i kada je korisnik otvorio naše stranice u više tabova
        if ($.cookie('popup-shown')) {
            return;
        }

        // detektiramo jesmo li izašli izvan document okvira
        if (event.relatedTarget !== null) {
            return;
        }

        if (!shouldPopUp()) {
            return;
        }

        document.removeEventListener('mouseout', showNewsletterModalBoxEvent);
        dontPopUpFor(90);

        $.get(url)
            .then(({ isSubscribed, email }) => {
                if (isSubscribed) {
                    return;
                }

                showModal();
                emailElement.value = email;
            });
    }

    function showModal() {
        const newsletterPopup = document.getElementById('newsletterPopup');
        newsletterPopup.classList.remove('hidden');
        closeModalBox = openModal(newsletterPopup, {
            overlayCss: ['newsletter'],
            onBeforeClose: () => dontPopUpFor(90),
        });
        ga('send', 'event', 'Naslovnica', 'Newsletter', 'Popup load');
    }

    function closeModalBoxAfter3Seconds() {
        // nakon 3 sekunde ukloni modal
        setTimeout(() => {
            closeModalBox();
        }, 3000);
    }

    function shouldPopUp() {
        const hasMinutePassed = Boolean(!$.cookie('minute_indicator'));
        // Je li korisnik u procesu rezervacije
        const isUserOnReservation = Boolean($('#R [data-step=2]:visible').length || $('#R [data-step=3]:visible').length);

        return hasMinutePassed && !isUserOnReservation;
    }

    function dontPopUpFor(days) {
        $.cookie('popup-shown', 'true', { expires: days, path: '/' });
    }
}

function removeFormAndShowSuccessMessage(newsletterSuccessText, emailElement) {
    const successMessage = newsletterSuccessText.replace('&&1&&', emailElement.value);
    const newsletterContent = document.getElementById('newsletterContent');
    newsletterContent.innerHTML = `<div class="newsletterSuccess">${successMessage}</div>`;
}

function showErrorMessage(errorMessage, emailElement) {
    const errorElement = getErrorElement();
    emailElement.classList.add('errorField');
    errorElement.textContent = errorMessage;

    function getErrorElement() {
        const existigErrorElement = document.getElementById('newsletterFormError');
        if (existigErrorElement) {
            return existigErrorElement;
        }

        return createNewErrorElement();
    }

    function createNewErrorElement() {
        const newErrorElement = document.createElement('span');
        newErrorElement.setAttribute('id', 'newsletterFormError');
        newErrorElement.classList.add('newsletterError');
        emailElement.parentNode.insertBefore(newErrorElement, emailElement.nextSibling);

        return newErrorElement;
    }
}

function urlContainReservationPath() {
    // 'reservation' se ne prevodi, pa ga možemo koristiti na ovaj način
    return !(window.location.href.indexOf('/reservation/') <= -1);
}
