export default function ToastMessage(toastType, message, time) {
    var self = this;
    var $toastMsg = $('.toastMsg');
    var toastWrap = $('.toastWrap');

    switch (toastType) {
        case 'error':
            $toastMsg.attr('class', 'toastError').prepend('<span class="closeToast"></span>');
            break;
        case 'success':
            $toastMsg.attr('class', 'toastSuccess');
            break;
        case 'warning':
            $toastMsg.attr('class', 'toastWarning');
            break;
        case 'info':
            $toastMsg.attr('class', 'toastInfo');
            break;
        case 'info-closable':
            $toastMsg.attr('class', 'toastInfo').prepend('<span class="closeToast"></span>');
            break;
        case 'promoCode':
            $toastMsg.attr('class', 'toastInfo').attr('id', 'promoCodeToast').prepend('<span class="closeToast"></span>');
        default:
            $toastMsg.attr('class', 'toastInfo');
    }

    self.showMsg = function(time) {
        $toastMsg.delay(time).show();
    };

    self.hideAfter = function(time) {
        $toastMsg.delay(time).slideUp(function() {
            $(this).remove();
        });
    };

    self.closeToast = function() {
        $(toastWrap).on('click', '.closeToast', function() {
            $(this).parent().remove();
        });
    };
}
