export default function getFormatGooglePredictionsForAutocomplete(poweredByGoogleIconUrl) {
    return formatGooglePredictionsForAutocomplete.bind(null, poweredByGoogleIconUrl);
}

/**
 * Formatira dohvaćene podatke za autocomplete.
 *
 * Format odgovara $.placescomplete pluginu (za source).
 *
 * @see http://api.jqueryui.com/autocomplete/#option-source
 *      *Function* varijaciju za dokumentaciju ulaznih parametara
 *
 * @param {string} poweredByGoogleIconUrl
 * @param {array} googlePlacePredictions
 *
 * @return {array} format koji podržava naš placescomplete plugin
 */
function formatGooglePredictionsForAutocomplete(poweredByGoogleIconUrl, googlePlacePredictions) {
    return [{
        name: `<img style="padding:10px;" src="${poweredByGoogleIconUrl}" />`,
        type: 'google-results',
        standalone: false,
        items: googlePlacePredictions.map((googleResult) => ({
            id: googleResult.id,
            value: googleResult.value,
            label: googleResult.description,
        })),
    }];
}
