import Taggle from 'taggle';
import debounce from 'lodash/debounce';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.core.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.widget.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.mouse.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.position.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.draggable.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.resizable.min';
// potrebno za Custom Actocomplete widget
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.autocomplete.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.button.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.datepicker.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.dialog.min';
// potrebno za Autocomplete widget
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.menu.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.tabs.min';
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.tooltip.min';
// jQuery Datepicker prijevodi
import '@PROJECT_ROOT/assets/desktop/js/jquery/ui/jquery.ui.i18n.custom';
import '@PROJECT_ROOT/assets/desktop/js/jquery/plugins/jquery.mousewheel.min'; // koristi ga jquery.jscrollpane
import '@PROJECT_ROOT/assets/desktop/js/jquery/plugins/jquery.jscrollpane.min'; // custom jQuery scroll
import '@PROJECT_ROOT/assets/desktop/js/jquery/plugins/jquery.cookie.min'; // jQuery plugin za rad sa cookies
import '@PROJECT_ROOT/assets/desktop/js/jquery/plugins/jquery.hint'; // custom plugin: hint za input polja
import '@PROJECT_ROOT/assets/desktop/js/jquery/plugins/jquery.prettyPhoto.min'; // jQuery popups
import '@PROJECT_ROOT/assets/desktop/js/library/autocomplete'; // custom autocomplete widget
// custom autocomplete widget
import {
    includeGoogleMapsAsynchronously,
    areGoogleMapsAlreadyLoaded,
} from '@PROJECT_ROOT/assets/desktop/js/library/GoogleMapsLoader';
import Ad from '@PROJECT_ROOT/assets/desktop/js/library/lib';
import {
    triggerGoogleAnalyticsSendEvent,
    triggerAnalyticsSendPageView,
} from '@PROJECT_ROOT/assets/desktop/js/library/triggerGoogleAnalyticsSend';
// inicijalizacije nakon ucitavanja svih skripti
import { prettyPhotoOptions, initPrettyPhoto, triggerOnload } from '@PROJECT_ROOT/assets/desktop/js/library/onload';
import initAutocomplete from '@PROJECT_ROOT/assets/desktop/js/initAutocompleteForHomepage';
import '@PROJECT_ROOT/assets/desktop/js/library/gdprConsentModalBox';
import initializeNewsletterModal from '@PROJECT_ROOT/assets/desktop/js/library/newsletterModalBox';
import '@PROJECT_ROOT/assets/desktop/js/library/oldBrowserNotice';

// koriste se u inline js-u homepage advanced forme
// @TODO Prebaciti u homepage entry point kad bude kreiran #33449
import formClear from '@PROJECT_ROOT/assets/desktop/js/search/formClear';
import { showResultCount } from '@PROJECT_ROOT/assets/desktop/js/search/searchResultCount';
import fetchStateFromHtml from '@PROJECT_ROOT/assets/shared/js/fetchStateFromHtml';
import { isFrame, isHtml } from '@PROJECT_ROOT/assets/shared/js/isPartnerService';

// Postavljamo globalne varijable jer se nažalost koriste inline u PHP template-ima. Ovo je *privremeno* rješenje,
// potrebno je sav inline kôd prebaciti u .js datoteke i tamo lokalno uključivati ove module.
window.includeGoogleMapsAsynchronously = includeGoogleMapsAsynchronously;
window.areGoogleMapsAlreadyLoaded = areGoogleMapsAlreadyLoaded;
window.Ad = Ad;
window.prettyPhotoOptions = prettyPhotoOptions;
window.initPrettyPhoto = initPrettyPhoto;
window.triggerOnload = triggerOnload;
window.initAutocomplete = initAutocomplete;
window.Taggle = Taggle;
window.formClear = formClear;
window.setGaqPush = triggerGoogleAnalyticsSendEvent;
window.setGaqPage = triggerAnalyticsSendPageView;
window.showResultCount = showResultCount;
window.debounce = debounce;

$(() => Ad.Menu.initLanguageSwitch());
$(() => {
    if (isFrame() || isHtml()) {
        return;
    }

    const newsletterPopupState = fetchStateFromHtml('newsletterPopupState');
    initializeNewsletterModal(newsletterPopupState);
});
