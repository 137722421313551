/* global ga */

// google event tracking
function triggerGoogleAnalyticsSendEvent(category, action, label = '', value = '') {
    if (typeof (ga) !== 'undefined') {
        if (typeof (value) !== 'undefined' && value !== '') {
            if (typeof (value) === 'number') {
                value = parseInt(value, 10); // eslint-disable-line no-param-reassign
            }

            ga(
                'send',
                'event',
                category ? category.trim() : '',
                action ? action.trim() : '',
                label && label.length > 0 ? label.trim() : '',
                value,
            );
        } else {
            ga(
                'send',
                'event',
                category ? category.trim() : '',
                action ? action.trim() : '',
                label && label.length > 0 ? label.trim() : '',
            );
        }
    }
}

// google pageview tracking
function triggerAnalyticsSendPageView() {
    if (typeof (ga) !== 'undefined') {
        ga('set', { location: `${window.location.protocol}//${window.location.hostname}${window.location.pathname}${window.location.search}` });
        ga('send', 'pageview');
    }
}

export {
    triggerGoogleAnalyticsSendEvent,
    triggerAnalyticsSendPageView,
};
