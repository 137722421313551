function createShowResultCount($post) {
    let resultsCountPromise = null;

    return ({ url, filters, submitButton, text }) => {
        if (resultsCountPromise !== null) {
            resultsCountPromise.abort();
        }

        resultsCountPromise = $post(url, filters);

        resultsCountPromise.then(({ totalResultsCount, offerMultipleUnitsOption, offerTwoPersonSearchResults }) => {
            const enable = totalResultsCount > 0;

            submitButton.innerHTML = totalResultsCount === 0
                ? noResultsText(text, offerMultipleUnitsOption, offerTwoPersonSearchResults)
                : text.foundResults.replace('{{1}}', totalResultsCount);

            enableSearchButton(submitButton, enable);
        });
    };
}

function noResultsText(text, offerMultipleUnitsOption, offerTwoPersonSearchResultsOption) {
    let useOptionTextOrEmpty = '';

    if (text.useOptionMultipleUnits && offerMultipleUnitsOption) {
        useOptionTextOrEmpty = text.useOptionMultipleUnits;
    }

    if (text.useOfferSearchForTwoPeopleOption && offerTwoPersonSearchResultsOption) {
        useOptionTextOrEmpty = text.useOfferSearchForTwoPeopleOption;
    }

    return `${text.noResults} ${useOptionTextOrEmpty}`.trim();
}

function setSubmitButtonToDefaultUnconfigured({ submitButton, defaultText }) {
    enableSearchButton(submitButton, true);
    submitButton.innerHTML = defaultText;
}

function enableSearchButton(submitButton, enable) {
    if (enable) {
        submitButton.disabled = false;
        submitButton.classList.remove('disabled');
    } else {
        submitButton.disabled = true;
        submitButton.classList.add('disabled');
    }
}

const showResultCount = () => createShowResultCount($.post);
const setSubmitButtonToDefault = (options) => setSubmitButtonToDefaultUnconfigured.bind(null, options);

export {
    showResultCount,
    setSubmitButtonToDefault,
    createShowResultCount,
    setSubmitButtonToDefaultUnconfigured,
};
