/* global Ad */
export default function promoCodeInit(message) {
    let interval = null;
    const closed = $.cookie('promoCodeStop') ? true : false; // eslint-disable-line no-unneeded-ternary
    const toastDuration = 7000;
    function init() {
        show();
        interval = window.setInterval(show, 10000 + toastDuration);
    }
    if (!closed) {
        setTimeout(init, 10000);
    }
    $('.toastWrap').on('click', '#promoCodeToast .closeToast', () => {
        $.cookie('promoCodeStop', true);
        clearInterval(interval);
    });
    function show() {
        if ($('#promoCode').data('show')) {
            Ad.ToastManager.add('promoCode', message, toastDuration);
        }
    }
}
