import { getFrameOffset } from '@PROJECT_ROOT/assets/shared/js/partnerMessaging';
import { heightChanged, legacyHeightChanged } from '@PROJECT_ROOT/assets/shared/js/frameToPartnerMessageTypes';

export default function openModal(content, options) {
    return makeOpenModal(content, options);
}

/**
 * Stvara funkciju za otvaranje modala.
 *
 * Dodatne opcije koje su podržane:
 *   - overlayCss: niz klasa koje treba primijeniti na overlay div modala
 *   - modalContentCss: niz klasa koje treba primijeniti na sadržaj modala
 *   - afterOpen: funkcija koja se pokreće odmah nakon otvaranja modala (prije postavljanja sadržaja), kao argument
 *     prima modal div element
 *   - onBeforeClose: funkcija koja se pokreće prije samog zatvaranja modala i čišćenja njegova sadržaja, funkcija
 *   prima sadržaj modala i bool parametar koji nam govori je li zatvarnje modala triggerano od strane aplikacije ili
 *   krajnjeg korisnika
 *   - fullscreen: bool, default false
 *
 * @param {string} content html sadržaj modala
 * @param {object} options
 *
 * @return {function} funkcija za zatvaranje modala, NAPOMENA: za *svaki* poziv ove funkcije se smatra da ga nije pokrenuo
 * krajnji korisnik, bez obzira jesmo li ju pozvali u event handleru nekog botuna ili ne.
 */
export function makeOpenModal(content, options) {
    const modalOverlay = document.createElement('div');
    let closeFunctionCalled = false;

    modalOverlay.classList.add('modal-box');
    if (options && options.overlayCss) {
        modalOverlay.classList.add(...options.overlayCss);
    }
    modalOverlay.style.display = 'block';

    const modalDiv = document.createElement('div');
    if (options && options.modalContentCss) {
        modalDiv.classList.add(...options.modalContentCss);
    }
    modalDiv.classList.add('modal-box__container');
    if (options && options.fullscreen) {
        modalDiv.classList.add('modal-box__container_fullscreen');
    }

    modalOverlay.appendChild(modalDiv);
    const closeButton = document.createElement('span');
    closeButton.classList.add('modal-box__close');

    const modalContent = document.createElement('div');
    modalDiv.appendChild(modalContent);
    modalContent.classList.add('modal-box__content');

    if (process.env.NODE_ENV !== 'production') {
        modalDiv.setAttribute('data-testid', 'modal-div');
        modalOverlay.setAttribute('data-testid', 'modal-overlay');
        closeButton.setAttribute('data-testid', 'modal-close-button');
        modalContent.setAttribute('data-testid', 'modal-content');
    }

    getFrameOffset().then((offset) => {
        if (document.body.clientHeight < modalDiv.clientHeight + 150) {
            document.body.style.height = `${modalDiv.clientHeight + 150}px`;
            window.parent.window.postMessage(heightChanged(), '*');
            window.parent.window.postMessage(legacyHeightChanged(document, modalDiv.clientHeight + 150), '*');
        }

        /**
         * Računamo udaljenost vrha modala od početka frame dokumenta.
         *
         * Za to trebamo znati koliko je vrh frame-a udaljen od početka viewporta (aka frame offset):
         *   1. ako je 0 ili manji, to znači da je vrh frame-a ispod vrha viewporta (vidljiv, ili negdje još ispod) =>
         *   otvaramo modal na vrhu (modalDiv.style.top = 0 + sitna margina)
         *
         *   2. ako je veći od nule (npr. 100), ovo znači da je vrh frame-a 100px iznad vrha viewporta => otvaramo modal
         *   udaljen 100px od vrha frame-a (modalDiv.style.top = 100 + sitna margina).
         *
         *   **Iznimka** je kada je frame skoro cijeli iznad viewporta, u tom slučaju ne želimo da modal pobjegne ispod
         *   frame-a, pa postavljamo da je udaljen od vrha framea za cijelu visinu frame-a minus visina modala (tj.
         *   modal se postavlja na dno frame-a tako da cijeli stane). Slika situacije koju time izbjegavamo (XX se neće
         *   vidjeti):
         *                                  ----------- ---       ---
         *   Korisnik ne vidi ovaj dio      | IFRAME  |  |         |
         *                                  |         |  | offset  |
         *                                  |         |  |         | document.body.clientHeight
         *   Vrh viewporta            ------| ------  |----        |    ---
         *                                  | |    |  |            |     |
         *   Dio vidljiv korisniku          --|----|---           ---    | modalDiv.clientHeight
         *                                    | XX |                     |
         *                                    ------                    ---
         */
        if (parseInt(offset, 10) < 0) {
            modalDiv.style.top = '30px';
        } else {
            modalDiv.style.top = document.body.clientHeight < modalDiv.clientHeight + parseInt(offset, 10)
                ? `${document.body.clientHeight - modalDiv.clientHeight - 30}px`
                : `${offset}px`;
        }
    });

    document.body.appendChild(modalOverlay);

    let originalContentLocation = null;

    // PRIPAZI: Ako prosljeđuješ element tj. njegovu referencu u tom slučaju appendChild() premješta orginalni element,
    // s referenciranog mjesta u modalBox, no ako koristiš HTML u obliku stringa, a izvukao si ga iz postojećeg document
    // okružja, onda češ prilikom otvaranja ModalBoxa imati duplicirani HTML
    if (typeof content !== 'string' && content.nodeType === window.Node.ELEMENT_NODE) {
        originalContentLocation = content.parentNode;
        modalContent.appendChild(content);
    } else {
        modalContent.innerHTML = content;
    }

    modalDiv.insertBefore(closeButton, modalDiv.firstChild);

    if (options && typeof options.afterOpen === 'function') {
        options.afterOpen(modalDiv);
    }

    const onBeforeClose = options && typeof options.onBeforeClose === 'function' ? options.onBeforeClose : () => {};

    function closeModal(isTriggeredByUser) {
        if (closeFunctionCalled) {
            return;
        }

        const additionalOptions = onBeforeClose(modalContent, isTriggeredByUser);
        if (typeof additionalOptions === 'object') {
            const { doNotCloseOnUserInteraction } = additionalOptions;
            if (doNotCloseOnUserInteraction && isTriggeredByUser) {
                return;
            }
        }

        document.body.style.height = '';
        closeFunctionCalled = true;

        if (originalContentLocation !== null) {
            originalContentLocation.appendChild(content);
        }

        modalOverlay.parentNode.removeChild(modalOverlay);
    }

    closeButton.addEventListener('click', () => {
        closeModal(/* triggeredByUser */true);
    });

    const fullscreen = options && typeof options.fullscreen === 'boolean';

    if (!fullscreen) {
        modalOverlay.addEventListener('mousedown', (event) => {
            if (!modalDiv.contains(event.target)) {
                closeModal(/* triggeredByUser */true);
            }
        });
    }

    return closeModal.bind(null, /* triggeredByUser */false);
}
