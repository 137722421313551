/* global Ad, google, googleMapsLoadedForSimpleForm */
import configureFetchPlaces from '@PROJECT_ROOT/assets/shared/js/placesFetch';
import makeFetchExternalAutocomplete from '@PROJECT_ROOT/assets/shared/js/externalAutocomplete';
import getFormatGooglePredictionsForAutocomplete from '@PROJECT_ROOT/assets/desktop/js/search/formatGoogleResults';
import makeGetPlaceInfo from '@PROJECT_ROOT/assets/desktop/js/search/mapsearch/GooglePlaceInfo';

export default function initAutocomplete(autocompleteUrl, poweredByGoogleUrl) {
    const $destinationLocation = $('#privateSearchForm_location');
    let typingTimer;
    let googleAutocompleteService;
    // inicijalno funkcija koja ne radi ništa kako bi autocomplete mjesta radio i dok google usluga još nema
    let getPlaceInfo = () => {};

    googleMapsLoadedForSimpleForm.then(() => {
        getPlaceInfo = makeGetPlaceInfo(
            new google.maps.places.PlacesService(document.createElement('div')),
            google.maps.places.PlacesServiceStatus,
        );

        const locationId = $('#privateSearchForm_location_id').val();
        fillLocationInputWithGooglePlaceInfo($('#privateSearchForm_location'), locationId, getPlaceInfo);

        googleAutocompleteService = new google.maps.places.AutocompleteService();
    });

    const fetchGoogleAutocomplete = makeFetchExternalAutocomplete(() => googleAutocompleteService);
    const formatGooglePredictions = getFormatGooglePredictionsForAutocomplete(poweredByGoogleUrl);

    const fetchPlacesForAutocomplete = configureFetchPlaces($.post, autocompleteUrl, fetchGoogleAutocomplete, formatGooglePredictions);

    $destinationLocation.placescomplete({
        source: fetchPlacesForAutocomplete,
        select: (event, ui) => {
            const locationId = ui.item.id;

            clearTimeout(typingTimer);
            $destinationLocation.data('latestLocation', ui.item.value);
            $('#privateSearchForm_location_id').val(locationId).change();

            $destinationLocation.addClass('ui-autocomplete-loading');
            fillLocationInputWithGooglePlaceInfo($destinationLocation, locationId, getPlaceInfo).done(() => {
                $destinationLocation.removeClass('ui-autocomplete-loading');
            });
        },
        autoFocus: true,
        delay: 500,
    }).keyup((e) => {
        if (e.which === 13) {
            $('.ui-autocomplete').hide();
        }
        clearTimeout(typingTimer);

        // Ako korisnik nije tipkao 500ms smatramo da je prestao tipkati i iniciramo change nad location poljem kako
        // bi izračunali broj rezultata sa unesenom lokacijom.
        typingTimer = setTimeout(() => {
            $destinationLocation.change();
        }, 500);
    });

    Ad.Autocomplete.initCompleteList('#privateSearchForm_location', { id: $destinationLocation.val() });
    $('#privateSearchForm_location_showAll').tooltip();
}

function fillLocationInputWithGooglePlaceInfo($locationInput, locationId, getPlaceInfo) {
    const deferred = $.Deferred();
    const googlePlaceFound = (name, location, viewport) => {
        $('#privateSearchForm_ne_lat').val(viewport.getNorthEast().lat());
        $('#privateSearchForm_ne_lng').val(viewport.getNorthEast().lng());
        $('#privateSearchForm_sw_lat').val(viewport.getSouthWest().lat());
        $('#privateSearchForm_sw_lng').val(viewport.getSouthWest().lng());
        $locationInput.val(name);
        $locationInput.data('latestLocation', name);
        $('#privateSearchForm_location').parent().addClass('hasText');
        deferred.resolve();
    };
    const googlePlaceNotFound = () => {
        $('#privateSearchForm_ne_lat').val('');
        $('#privateSearchForm_ne_lng').val('');
        $('#privateSearchForm_sw_lat').val('');
        $('#privateSearchForm_sw_lng').val('');
        deferred.resolve();
    };

    getPlaceInfo(locationId, googlePlaceFound, googlePlaceNotFound);

    return deferred.promise();
}
