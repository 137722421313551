/* global head */
import openModal from '@PROJECT_ROOT/assets/shared/js/modalBox';

$(function () {
    $(document)
        // Koristimo .off() pa .on() i namespaced event radi pjaxa, vidi https://home/projects/it/wiki/JavaScript#PJAX
        .off('click.gdpr', '.termsAndConditionsLink')
        .on('click.gdpr', '.termsAndConditionsLink', function (e) {
            e.preventDefault();
            getModalBoxData($(this));
        });

    $(document)
        // vidi komentar iznad za .termsAndConditionsLink
        .off('click.gdpr', '.js-privacyPolicyLink')
        .on('click.gdpr', '.js-privacyPolicyLink', function (e) {
            e.preventDefault();
            getModalBoxData($(this));
        });

    function getModalBoxData($link) {
        $.post($link[0].href).then(function (data) {
            if (data !== '') {
                openModal(data, { overlayCss: ['privacyTermsAndConditions']});
            }
        });
    }
});
