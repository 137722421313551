/**
 * Po broju riječi računa vremenski period(ms) trajanja poruke.
 *
 * @param string message
 * @param int defaultMinimumTime
 */
export default function calculateToastMessageDuration(message, defaultMinimumTime) {
    const miliSecondsPerWord = 300;
    const words = message.split(' ');
    const delay = words.length * miliSecondsPerWord;

    return delay < defaultMinimumTime ? defaultMinimumTime : delay;
}
