let latestLocation;
let latestLocationRequest;
const autocompleteHolder = $('.ui-autocomplete');
let locationFieldId;

/**
 * Naše proširenje jQueryUI autocomplete plugina.
 *
 * Način instanciranja je analogan autocomplete pluginu:
 * $('neki element').placescomplete({objekt s istim opcijama kao i autocomplete plugin }) - iznimka je što se u opcijama
 * dodatno prosljeđuje i parametar "useNearMe", kojim biramo želimo li da autocompleter nudi opciju "U mojoj blizini"
 *
 * VAŽNO: budući da je naziv plugina promijenjen u "placescomplete", svi custom jQueryUI eventovi su također
 * preimenovani, npr.:
 * * autocompletechange => placescompletechange
 * * autocompleteclose => placescompleteclose
 *
 * Ostatak korištenja je manje više isti kao i za originalni plugin. Dokumentacija autocomplete plugina:
 * http://api.jqueryui.com/autocomplete/
 */
$.widget('custom.placescomplete', $.ui.autocomplete, {
    _initSource() {
        if (!latestLocation) {
            latestLocation = { id: this.element.val() };
        }

        if (!locationFieldId) {
            locationFieldId = $(`#${this.element.attr('id')}_id`);
        }

        if (!this.options.select) {
            this.options.select = function (event, ui) {
                latestLocation.id = ui.item.value;
                locationFieldId.val(ui.item.id);
            };
        }

        if (!this.options.search) {
            this.options.search = function () {
                autocompleteHolder.hide();
            };
        }

        if (!this.options.open) {
            this.options.open = function () {
                $(this).placescomplete('widget').scrollTop(0);
            };
        }

        if (!this.options.useNearMe) {
            this.options.useNearMe = true;
        }

        if (typeof (this.options.source) === 'string') {
            this.source = function (request, response) {
                if (latestLocationRequest) {
                    latestLocationRequest.abort();
                }

                latestLocationRequest = $.post(
                    this.options.source,
                    { term: request.term, useNearMe: this.options.useNearMe },
                    (data) => {
                        response(data);
                    },
                );
            };
        } else if (typeof (this.options.source) === 'function') {
            this.source = this.options.source;
        }
    },
    _renderMenu(ul, items) {
        document.querySelectorAll('.tooltip').forEach((tooltipElement) => {
            // Zatvaramo tooltipove ako su otvoreni, jedan po jedan kako bi *za svakog* mogli hendlati grešku.
            //
            // Kad bi radili sa $('.tooltip').tooltip('close'); ako prvi u tom nizu bude neki za koji još nije
            // inicijaliziran tooltip, bacit će se greška, a ostali tooltipovi se neće zatvoriti.
            try {
                $(tooltipElement).tooltip('close');
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    // Ignoriramo samo grešku sa ovom porukom
                    if (error.message.indexOf('cannot call methods on tooltip prior to initialization') === -1) {
                        throw error;
                    }
                }
            }
        });

        const self = this;
        $.each(items, (index, locationGroup) => {
            if (!locationGroup.standalone) {
                ul.append(`<li class="ul-autocomplete-${locationGroup.type}">${locationGroup.name}</li>`);
            }
            $.each(locationGroup.items, (locationIndex, location) => {
                // eslint-disable-next-line no-underscore-dangle
                self._renderItem(ul, { value: location.value, label: location.label, id: location.id });
            });
        });

        this.element.removeClass('ui-autocomplete-loading');
    },
    _renderItem(ul, item) {
        return $('<li></li>')
            .data('item.autocomplete', item)
            .append($('<a></a>').html(item.label))
            .appendTo(ul);
    },
});
