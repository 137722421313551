/* global ga */
import calculateToastMessageDuration from '@PROJECT_ROOT/assets/desktop/js/library/ToastCalculator';
import ToastMessage from '@PROJECT_ROOT/assets/desktop/js/library/ToastMessages';
import promoCodeInit from '@PROJECT_ROOT/assets/desktop/js/library/promoCode';
import { FRAME_OFFSET_CHANGED } from '@PROJECT_ROOT/assets/shared/js/partnerToFrameMessageTypes';
import { getMessageDataAsObject } from '@PROJECT_ROOT/assets/shared/js/partnerMessaging';
import { getFrameOffset } from '@PROJECT_ROOT/assets/shared/js/partnerMessaging';
import { isFrame } from '@PROJECT_ROOT/assets/shared/js/isPartnerService';

var Ad = {
    Dom: {
        toList: function(items) {
            var list = $('<ul/>');
            $.each(items, function(index, value) {
                list.append($('<li/>').html(value));
            });

            return list;
        },
        browserCheck: function(no_cookies_text, no_cookies_link) {
            // https://raw.githubusercontent.com/Modernizr/Modernizr/master/feature-detects/cookies.js
            //
            // navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
            // configurations. For example, when blocking cookies via the Advanced
            // Privacy Settings in IE9, it always returns true. And there have been
            // issues in the past with site-specific exceptions.
            // Don't rely on it.

            // try..catch because some in situations `document.cookie` is exposed but throws a
            // SecurityError if you try to access it; e.g. documents created from data URIs
            // or in sandboxed iframes (depending on flags/context)

            var cookiesSupported;
            try {
                // Create cookie
                document.cookie = 'cookietest=1';
                cookiesSupported = document.cookie.indexOf('cookietest=') != -1;
                // Delete cookie
                document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
            }
            catch (e) {
                cookiesSupported = false;
            }
            if (!cookiesSupported) {
                $('#topInfoBar').append('<div class="topInfoBox"><p>' + no_cookies_text + ' ' + no_cookies_link + '?</p></div>');
            }
        },
        abortRequests: function() {
            for (var req in window.requestList) {
                if (window.requestList[req].readyState != 4) {
                    window.requestList[req].abort();
                }
            }
        },
        initializePrivateAccommodationFacilityStatisticsHandler: function(endpoint) {
            $.ajax({url: endpoint, dataType: 'html'}).then(function(facilityStatisticsHTML) {
                $('#facilityStatistics').html(facilityStatisticsHTML).fadeIn(600, function() {
                    $(this).tooltip({track: true, position: {my: 'left+20 top+10', at: 'right bottom', collision: 'flipfit'}});
                });
            });
        },
        initializePrivateAccommodationFacilitiesCompactStatisticsHandler: function(endpoint) {
            $.ajax({url: endpoint, dataType: 'json'}).then(function(facilityCompactStatisticsHTMLs) {
                for (var facilityId in facilityCompactStatisticsHTMLs) {
                    $('#facilityCompactStatistics_'+facilityId).html(facilityCompactStatisticsHTMLs[facilityId]).fadeIn(600, function() {
                        $(this).tooltip({track: true, position: {my: 'left+20 top+10', at: 'right bottom', collision: 'flipfit'}});
                    });
                }
            });
        }
    },
    Error: {
        show: function(errors, dialogTitle) {
            var list = $('<ul/>');
            $.each(errors, function(index, value) {
                list.append($('<li/>').html(value));
            });

            const dialog = $("#errorPane").html(list).dialog({
                modal: true,
                title: dialogTitle,
                resizable: false,
                draggable: false,
                buttons: {
                    Ok: function() {
                        $(this).dialog('close');
                    }
                }
            });

            return dialog;
        },
        initializeAjaxErrorHandler: function() {
            var dbgErrCount = 0;
            $(document).ajaxError(function(event, xhr, ajaxOptions, thrownError) {
                if (dbgErrCount < 4) // avoid possibility of request loop, 3 max
                    if (!(xhr.readyState == 0 || xhr.status == 0)) // do not track canceled requests
                        Ad.Error.handleAjax(ajaxOptions, xhr);
                dbgErrCount++;
            });
        },
        handleAjax: function(HXR, xhr) {
            var errReport = {agent: null, xhr: null};
            var xhrErr = {type: null, url: null, statusCode:null, statusText:null, data:null, cache: null, contentType: null, crossDomain: null}; // data:null
            errReport['agent'] = Ad.Error.getAdUseragentData(window, document);

            for (var p in xhrErr) { // Analyze xhr
                if (HXR.hasOwnProperty(p))
                    xhrErr[p] = HXR[p];
            }

            xhrErr['statusCode'] = xhr.status;
            xhrErr['statusText'] = xhr.statusText;

            errReport['xhr'] = xhrErr;

            if ((xhrErr.url != 'undefined' && xhrErr.url != null) && jQuery.inArray("XhrErrReport", xhrErr.url.split('/')) == -1) // do not report self error
                if (typeof is_bot_check != 'undefined' && !is_bot_check) // avoid crawl boots
                    Ad.Error.reportExceptionViaAjax(errReport);
        },
        getAdUseragentData: function(window, document) {
            var agentData = {href: null, userAgent: null, ip: null}; // Use only to get and format data we are interested in
            agentData['href'] = window.location.href;
            agentData['userAgent'] = navigator.userAgent;
            agentData['ip'] = (window.local_vac_ip != 'undefined') ? window.local_vac_ip : '192.168.1.1';
            return agentData;
        },
        reportExceptionViaAjax: function(jsonDataObj) {
            $.ajax({
                url: "/en/error/XhrErrReport",
                type: "POST",
                data: {dbg: jsonDataObj}
            });
            return true;
        }
    },
    Menu: {
        highlightActive: function(menuItemTitle) {
            var activeItem = $('#menu > li').children('a:contains(' + menuItemTitle + ')');

            if (activeItem)
            {
                activeItem.addClass('active');
            }
        },
        initLanguageSwitch: function() {
            $('#allLanguages').on('click', '.languageChange', function(e) {
                e.preventDefault();
                var that = $(this);

                that.parents('#allLanguages').removeClass('active');
                if (window.everPushedSomething && $('#allLanguages').data('language-url')!=='') {
                    $.ajax({
                        type: "POST",
                        url: $('#allLanguages').data('language-url'),
                        data: {
                            url: window.location.href,
                            lang: that.data('lang')
                        },
                        success: function(res) {
                            window.location.href = res;
                        },
                        error: function() {
                            window.location.href = that.attr('href');
                        }
                    });

                } else {
                    var value = $(this).attr('href');
                    if (value) {
                        var hash = window.location.hash.substr(1);
                        if (hash.length > 0) {
                            value = value + '#' + hash;
                        }
                        window.location.href = value;
                    }
                }
            });
        }
    },
    Datepicker: {
        initRange: function(fromDatePickerSelector, toDatePickerSelector, options, callback) {
            var $dateFrom = $(fromDatePickerSelector + '_visual');
            var $dateTo = $(toDatePickerSelector + '_visual');

            // Default datum je određen u komentaru #21 ticketa #4988
            var currentDate = new Date();
            var seasonYear = (currentDate > new Date(currentDate.getFullYear(), 9, 1))
                ? currentDate.getFullYear() + 1
                : currentDate.getFullYear();

            if (currentDate < new Date(seasonYear, 6, 1)) {
                var defaultDate = new Date(seasonYear, 6, 1);
            } else {
                var defaultDate = currentDate;
            }

            function addDeleteButton($dateField) {
                // Set timeout je iz nekog razloga potreban jer se inače botun neće prikazati.
                setTimeout(function () {
                    var $buttonPane = $dateField.datepicker('widget').find('.ui-datepicker-buttonpane');
                    if ($buttonPane.find('.clearButton').length > 0) {
                        $buttonPane.find('.clearButton').remove();
                    }

                    var clearBtn = $([
                        '<button class="clearButton ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all">',
                        options.deleteButton.text,
                        '</button>',
                    ].join(''));

                    clearBtn.prependTo($buttonPane);

                    clearBtn.on('click', function clearButtonClickHandler() {
                        $.datepicker._clearDate($dateField);
                        $dateField.val('').trigger('change');
                        $dateField.datepicker("hide");
                    });
                }, 1);
            }

            var defaultOptions = {
                selectable: function(dt) { return true; },
                onCloseFrom: function() {},
                onCloseTo: function() {},
                beforeShow: function(inputElement, datePickerObject) {
                    var selectedDateTo = $(toDatePickerSelector + '_visual');
                    var selectedDateFrom = $(fromDatePickerSelector + '_visual');

                    // ONLY DateFrom selected
                    if ((selectedDateTo.datepicker("getDate") != null) && (selectedDateFrom.datepicker("getDate") === null))
                    {
                        var selectedDateToTime = selectedDateTo.datepicker("getDate");
                        selectedDateFrom.datepicker("option", "defaultDate", new Date(selectedDateToTime.getFullYear(), selectedDateToTime.getMonth() - 1, 1));
                        selectedDateFrom.datepicker("refresh");
                    }

                    if (options.deleteButton) {
                        addDeleteButton($(inputElement));
                    }
                },
                beforeShowDayFrom: function(dt) {
                    var time = dt.getTime();
                    var dpDateFrom = $dateFrom.datepicker('getDate');
                    var dpDateTo = $dateTo.datepicker('getDate');

                    if (dpDateFrom && dpDateTo)
                    {
                        if (time == dpDateFrom.getTime())
                            return [options.selectable(dt), 'unavail_temp_start', 'Start date'];
                        else if (time > dpDateFrom.getTime() && time < dpDateTo.getTime())
                            return [options.selectable(dt), 'unavail_temp', 'Selected date'];
                        else if (time == dpDateTo.getTime())
                            return [options.selectable(dt), 'unavail_temp_end', 'End date'];
                    }
                    else if (dpDateTo)
                    {
                        if (time == dpDateTo.getTime())
                            return [options.selectable(dt), 'unavail_temp_end', 'End date'];
                    }

                    return [options.selectable(dt), ''];
                },
                beforeShowDayTo: function(dt) {
                    var time = dt.getTime();
                    var dpDateFrom = $dateFrom.datepicker('getDate');
                    var dpDateTo = $dateTo.datepicker('getDate');

                    if (dpDateFrom && dpDateTo)
                    {
                        if (time == dpDateFrom.getTime())
                            return [false, 'unavail_temp_start', 'Start date'];
                        else if (time > dpDateFrom.getTime() && time < dpDateTo.getTime())
                            return [options.selectable(dt), 'unavail_temp', 'Selected date'];
                        else if (time < dpDateFrom.getTime())
                            return [false, ''];
                        else if (time == dpDateTo.getTime())
                            return [options.selectable(dt), 'unavail_temp_end', 'End date'];
                    }
                    else if (dpDateFrom)
                    {
                        if (time < dpDateFrom.getTime())
                            return [false, ''];
                        else if (time == dpDateFrom.getTime())
                            return [options.selectable(dt), 'unavail_temp_start', 'Start date'];
                    }
                    else if (dpDateFrom == null)
                    {
                        if ((dpDateTo != null) && (time == dpDateTo.getTime()))
                            return [options.selectable(dt), 'unavail_temp_end', 'End date'];
                    }

                    return [options.selectable(dt), ''];
                },
                openToAfterSelectingFromDate: false,
                allowKeyboardInput: true,
                deleteButton: false,
                onChangeMonthYear: function onChangeMonthYearHandler() {
                    if (options.deleteButton) {
                        addDeleteButton($(this));
                    }
                },
            };

            if (options == undefined) {
                options = {};
            }

            options = $.extend(defaultOptions, options);

            // Datumi na kalendaru mogu ići do kraja sljedeće godine #6496
            var maxDateDay = (new Date(((new Date).getFullYear() + 1), 11, 31));

            var fromOptions = {altFormat: 'yy-mm-dd', numberOfMonths: 2, showButtonPanel: true, minDate: '+0d',
                maxDate: maxDateDay, changeMonth: false, changeYear: false, beforeShowDay: options.beforeShowDayFrom,
                beforeShow: options.beforeShow, onClose: options.onCloseFrom, altField: fromDatePickerSelector,
                defaultDate: defaultDate, onChangeMonthYear: options.onChangeMonthYear, currentText: options.currentText
            };

            var toOptions = {altFormat: 'yy-mm-dd', numberOfMonths: 2, showButtonPanel: true, minDate: '+1d',
                maxDate: maxDateDay, changeMonth: false, changeYear: false, beforeShowDay: options.beforeShowDayTo,
                beforeShow: options.beforeShow, onClose: options.onCloseTo, altField: toDatePickerSelector,
                defaultDate: defaultDate, gotoCurrent: false, onChangeMonthYear: options.onChangeMonthYear
            };

            if (options.from !== undefined) {
                Object.assign(fromOptions, options.from);
            }

            if (options.to !== undefined) {
                Object.assign(toOptions, options.to);
            }

            $dateFrom.datepicker(fromOptions);
            $dateTo.datepicker(toOptions);

            function compareMonth(date1, date2) {
                if (date2.getYear() > date1.getYear())
                    return true;
                else if (date2.getMonth() > date1.getMonth())
                    return true;
                else
                    return false;
            }

            if (!options.allowKeyboardInput) {
                $dateFrom.prop('readonly', true);
                $dateTo.prop('readonly', true);
            }

            function dateFromChange() {
                var fromDate = $dateFrom.datepicker('getDate');

                if (fromDate) {
                    var minDateTo = fromDate;
                    minDateTo.setDate(minDateTo.getDate() + 1);

                    var clearDate = $dateTo.datepicker('getDate') < minDateTo;

                    $dateTo.datepicker('option', 'minDate', minDateTo);
                    if (clearDate) {
                        $dateTo.datepicker('setDate', null);
                    }

                    $dateTo.datepicker('option', 'defaultDate', minDateTo);
                    $dateTo.datepicker('option', 'showCurrentAtPos', (compareMonth($dateFrom.datepicker('getDate'), fromDate) ? 1 : 0));
                }

                if ($dateFrom.datepicker('getDate') && $dateTo.datepicker('getDate')) {
                    $dateTo.datepicker('option', 'showCurrentAtPos', (compareMonth($dateFrom.datepicker('getDate'), $dateTo.datepicker('getDate')) ? 1 : 0));
                }
            }

            $dateFrom.change(function () {
                dateFromChange();
                var fromDate = $dateFrom.datepicker('getDate');
                var today = new Date();

                // ako je datum početka u prošlosti, nakon zatvaranja poruke sa greškom, postavlja datum na današnji i vraća fokus natrag na dateFrom polje
                // eliminiratui utjecaj vremena - za dateFrom i staviti da je vrijeme 21:55, a ne 0:0
                if (fromDate > 0) {
                    fromDate.setHours(21, 55);
                }

                $(fromDatePickerSelector).change();
            });

            $dateTo.change(function () {
                var fromDate = $dateFrom.datepicker('getDate');
                var toDate = $(this).datepicker('getDate');

                // Ako je mjesec kraja intervala veći od mjeseca početka pokazuje se prethodni mjesec
                if (toDate && fromDate) {
                    $dateTo.datepicker('option', 'showCurrentAtPos', (compareMonth(fromDate, toDate) ? 1 : 0));

                    if (toDate <= fromDate) {
                        Ad.Error.show(['Termin završetka ne smije biti jednak ili prije termina početka intervala'], 'Error');
                        $dateFrom.change();
                    }
                }

                $(toDatePickerSelector).change();
            });

            $(function() {
                if ($(fromDatePickerSelector).val() != '') {
                    $dateFrom.datepicker('setDate', $.datepicker.parseDate('yy-mm-dd', $(fromDatePickerSelector).val()));
                }

                $dateFrom.blur(function() {
                    if (!$(this).val()) {
                        $(fromDatePickerSelector).val('');
                    }
                });

                if ($(toDatePickerSelector).val() != '') {
                    $dateTo.datepicker('setDate', $.datepicker.parseDate('yy-mm-dd', $(toDatePickerSelector).val()));
                }

                $dateTo.blur(function() {
                    if (!$(this).val()) {
                        $(toDatePickerSelector).val('');
                    }
                });

                if (typeof callback == 'function') {
                    callback.call(this);
                }
            });

            dateFromChange();
            $dateTo.change();

            if (options.openToAfterSelectingFromDate) {
                $dateFrom.on('change', function() {
                    var toDate = $dateTo.datepicker('getDate');
                    if (toDate) {
                        return;
                    }

                    var fromDate = $dateFrom.datepicker('getDate');
                    if (!fromDate) {
                        return;
                    }

                    // bez ovog timeouta ne radi
                    setTimeout(function() {
                        $dateTo.datepicker('setDate', (new Date()).setDate(fromDate.getDate() + 1));
                        $dateTo.datepicker('show');
                    });
                });
            }

            // Isključuje browser prevođenje za datepicker (#38645)
            document.querySelectorAll('.ui-datepicker.ui-widget').forEach((widget) => {
                widget.classList.add('notranslate');
            });
        },
        init: function(datePickerSelector, overrides, locale) {
            var datePicker = $(datePickerSelector + '_visual');

            var options = {minDate: new Date(1910, 1, 1), maxDate: '+5y', defaultDate: 'today', yearRange: '1910:+5',
                changeMonth: true, changeYear: true, altField: datePickerSelector, altFormat: 'yy-mm-dd'
            };

            if (overrides !== undefined)
                $.extend(options, overrides);

            $(function() {
                datePicker.datepicker(options);
                datePicker.datepicker('setDate', $.datepicker.parseDate('yy-mm-dd', $(datePickerSelector).val()));
                datePicker.blur(function() {
                    if (!$(this).val())
                        $(datePickerSelector).val('');
                });

                if (locale !== undefined)
                    datePicker.datepicker('option', $.datepicker.regional[locale]);
            });
        }
    },
    Autocomplete: {
        location: function(element, url) {
            $(element).placescomplete({source: url});
        },
        locationByType: function(element, url, type) {
            $(element).placescomplete({
                source: function(request, response) {
                    request.type = type;
                    $.getJSON(url, request, function(data) {
                        var places = data[0];
                        response([{
                                name: places.name,
                                type: places.type,
                                items: $.map(places.items, function(item) {
                                    return {
                                        label: item.label,
                                        value: item.value
                                    };
                                })
                            }]);
                    });
                }});
        },
        initCompleteList: function(element, latestLocationTmp) {
            var locationField = $(element);
            locationField.data('latestLocation', latestLocationTmp.id);
            var locationFieldId = $(element + '_id');
            var autocompleteHolder = $('.ui-autocomplete');

            $(element + '_showAll').click(function(event) {
                event.preventDefault();

                if (autocompleteHolder.css('display') != 'block') {
                    locationField.placescomplete('search', '0').focus();
                } else {
                    autocompleteHolder.show();
                }
            });

            // Ovo služi kako bi se izbrisao id mjesta iz skrivenog locationId input polja u slučaju kada korisnik unese
            // neki string koji nemamo u našoj bazi. Tada želimo da se pretražuje po location stringu koji je korisnik
            // unio, a ne po id-ju lokacije.
            locationField.on('change', function() {
                if (locationField.val() !== locationField.data('latestLocation')
                    || locationField.val() === '' || locationField.val() === undefined) {

                    locationFieldId.val('').change();
                }

                locationField.data('latestLocation', locationField.val());
            });
        },
        bank: function(url, nameElement, numberElement) {
            $(document).off('click', nameElement + '_showAll');
            $(document).off('keyup', nameElement);

            $(nameElement).autocomplete({
                source: url,
                select: function(e, ui) {
                    e.preventDefault();
                    $(this).val(ui.item.value.name);
                    $(numberElement).val(ui.item.value.base_account);
                    if ($(this).val() == '')
                        $(numberElement).removeProp('readonly');
                    else
                        $(numberElement).prop('readonly', true);
                }
            }).data('autocomplete')._renderItem = function(ul, item) {
                return $("<li></li>")
                    .data("item.autocomplete", item)
                    .append($("<a></a>").html(item.label))
                    .appendTo(ul);
            };

            $(document).on('keyup', nameElement, function() {
                if ($(this).val() == '')
                    $(numberElement).removeProp('readonly').val('');
                else
                    $(numberElement).prop('readonly', true);
            });

            $(document).on('click', nameElement + '_showAll', function(e) {
                e.preventDefault();
                $(nameElement).autocomplete('search', '0').focus();
            });
        },
    },
    Search: {
        /**
         * Vrši ajax upit na zadani url (urlToFetchOptimalUrl) kako bi dobio odgovarajući 'pravi' url (sa query parametrima
         * pretrage), nad kojim se vrši pjax poziv.
         */
        fetchOptimalUrlAndRedirect: function(data, urlToFetchOptimalUrl, errorPaneTitle, pjax) {
            var requestType = (window.local_vac_html) ? 'POST' : 'GET';
            if (pjax) {
                $('#presentationOpacity').show();
                $('#pjaxHolder').hide('slide', {direction: 'left'}, 300, function() {
                    $(this).addClass('magicHolder');
                });
            } else {
                $("#newLoadingContent, .advancedLoader").show();
            }

            $.ajax({type: requestType, url: urlToFetchOptimalUrl, data: data, cache: false, success: function(data) {
                if (data.errors) {
                    Ad.Error.show(data.errors, errorPaneTitle);
                    if (pjax) {
                        setTimeout( function() {
                            $('#presentationOpacity').hide();
                            $('#pjaxHolder').removeClass('magicHolder').show();
                        }, 300 );
                    } else {
                        $("#newLoadingContent, .advancedLoader").show();
                    }
                } else {
                    if (window.searchResultsCount) {
                        window.searchResultsCount++;
                    } else {
                        window.searchResultsCount = 1;
                    }

                    if (pjax) {
                        $.pjax({url: data.url, container: '#serviceContent', fragment: '#pjaxFragment', timeout: 5000, scrollTo: false});
                    } else {
                        window.location.href = data.url;
                        return false;
                    }
                }
            }});
        },
        getSearchFormAjaxData: function(formId) {
            return $("#" + formId).serialize();
        },
        checkAdditionalFiltersPossibility: function() {
            var paramsValid = true;
            var validationElements = {
                dateFrom: $(arguments[0].dateFrom),
                dateFromSelector: arguments[0].dateFrom,
                dateTo: $(arguments[0].dateTo),
                dateToSelector: arguments[0].dateTo,
                dateDeviation: $(arguments[0].dateDeviation),
                personSelect: $(arguments[0].personSelect),
                personDistribution: $(arguments[0].personDeviation)
            };

            for (var validationElementKey in validationElements) {
                if (validationElements[validationElementKey].length < 1)
                    paramsValid = false;
            }

            if (paramsValid) {
                var validationFunctions = {
                    checkDateDeviationPossibility: function() {
                        var dateFromElement = $(validationElements.dateFromSelector);
                        var dateToElement = $(validationElements.dateToSelector);
                        var dateFromValue = dateFromElement.val().length > 0 ? dateFromElement.val() : $(validationElements.dateFromSelector + "_visual").val();
                        var dateToValue = dateToElement.val().length > 0 ? dateToElement.val() : $(validationElements.dateToSelector + "_visual").val();

                        if (dateFromValue.length > 0 && dateToValue.length > 0)
                            validationElements.dateDeviation.prop("disabled", false);
                        else
                            validationElements.dateDeviation.prop("disabled", true);
                    },
                    checkPersonDistributionPossibility: function() {
                        if (parseInt(validationElements.personSelect.val()) > 0)
                            validationElements.personDistribution.prop("disabled", false);
                        else
                            validationElements.personDistribution.prop("disabled", true);
                    }
                };
                validationElements.dateFrom.add(validationElements.dateTo).on("change", function() {
                    validationFunctions.checkDateDeviationPossibility();
                }).trigger("change");
                validationElements.personSelect.on("change", function() {
                    validationFunctions.checkPersonDistributionPossibility();
                }).trigger("change");
            }
        },
        /**
         * Dohvaća url prezentacije smještajne jedinice sa zadanim parametrima pretrage.
         *
         * @param {string} url
         * @param {object} params objekt sa propertijima: unitId , from, to i persons (sve stringovi)
         *
         * @return {object} jqXHR Promise koji u then() callback šalje novi url smještajne jedinice.
         */
        fetchUnitUrl: function fetchUnitUrl(url, params) {
            return $.post(url, params).then(function (response) {
                return JSON.parse(response).url;
            });
        }
    },
    Translate: {
        node: function(node, reviewId, langTo, translateScriptUrl) {
            var orgText = $(node).html();
            $.ajax({
                type: "POST",
                url: translateScriptUrl,
                data: "id=" + reviewId + "&langTo=" + langTo,
                beforeSend: function() {
                    $(node).html('<span class="horizontalLoader"></span>');
                },
                success: function(data) {
                    if (data != '')
                        $(node).html(data);
                    else
                        $(node).html(orgText);
                },
                error: function() {
                    $(node).html(orgText);
                }
            });
        }
    },
    GAdimensions: {
        send: function(params) {
            var customAction = '',
                customData = {
                    'hitType': 'event',
                    'eventCategory': 'Custom dimensions',
                    'nonInteraction': 1
                };

            for (var prop in params) {
                customData[prop] = params[prop];
                customAction += prop+' ';
            }

            customData.eventAction = customAction;
            Ad.GAdimensions.checkLoadedAndSend(customData);
        },
        sendHrental: function(objId, pageType, totalValue, startDate, endDate) {
            var customData = {
                'hitType': 'event',
                'eventCategory': 'Custom dimensions',
                'eventAction': pageType,
                'nonInteraction': 1,
                'dimension1': objId,
                'dimension2': pageType
            };
            if (totalValue !== '') { customData.dimension3 = totalValue; }
            if (startDate !== '') { customData.dimension4 = startDate; }
            if (endDate !== '') { customData.dimension5 = endDate; }
            Ad.GAdimensions.checkLoadedAndSend(customData);
        },
        checkLoadedAndSend: function(dimensionsData) {
            if (typeof(window.ga) !== 'undefined') {
                ga('send', dimensionsData);
            } else {
                setTimeout(function(){
                    Ad.GAdimensions.checkLoadedAndSend(dimensionsData);
                }, 1000);
            }
        }
    },
    recentHistory: {
        historyViewport: 0,
        historyChanged: false,
        clearSeted: false,
        scrollSeted: false,
        init: function() {
            this.setViewport();
            this.setScroll();
            this.initClear();
        },
        setViewport: function() {
            this.historyViewport = $('#historyWrap').offset().top - $(window).height() - 100;
        },
        setScroll: function() {
            var self = this,
                $window = $(window);
            self.scrollSeted = true;
            $window.on('scroll.history', function() {
                if ($window.scrollTop() > self.historyViewport) {
                    $window.off('scroll.history');
                    self.scrollSeted = false;
                    self.loadHistory();
                }
            }).scroll();
        },
        add: function(type, id) {
            var cookie = $.cookie('cookie_history');

            if (cookie == null)
            {
                var cookieArray = new Object;
            }
            else
            {
                var cookieArray = this.parser(cookie);
            }
            var d = new Date();

                    cookieArray[type +'-'+ id] =  d.getTime().toString();

            var cookieSort = this.arraySort(cookieArray, function(a, b) {
                return b - a;
            });

            $.cookie('cookie_history', $.param(cookieSort), {'expires': 100, 'path': '/'});
            this.historyChanged = true;
        },
        parser: function(str) {
            var items = str.split('&');
            var obj = new Object;
            var itemsCount = items.length > 24 ? 24 : items.length;
            for (var i = 0; i < itemsCount; i++)
            {
                var item = items[i].split('=');
                var accommodationObject = item[0].split('-');
                if (-1 != jQuery.inArray(accommodationObject[0], ['p', 'h', 'r', 'l', 'b', 's']))
                {
                    obj[item[0]] = item[1];
                }
            }
                return obj;
        },
        loadHistory: function() {
            var self = this;
            $.get($('#historyWrap').data('historyurl'), function(data) {
                if (data.length > 0) {
                    $('#historyWrap').html(data).tooltip({track: true, position: {my: "left+20 top+10", at: "right bottom"}});

                    self.initInterface();
                    self.formatdate();
                    self.historyChanged = false;
                }
            });
        },
        formatdate: function() {
            $('.historyItem img').each(function() {
                var titleDate = new Date(parseInt(this.title));
                var currentDate = new Date();

                if (titleDate.getFullYear() == currentDate.getFullYear() && titleDate.getMonth() == currentDate.getMonth() && titleDate.getDate() == currentDate.getDate())
                {
                    var hours = titleDate.getHours().toString().length > 1 ? titleDate.getHours().toString() : '0' + titleDate.getHours().toString();
                    var minutes = titleDate.getMinutes().toString().length > 1 ? titleDate.getMinutes().toString() : '0' + titleDate.getMinutes().toString();

                    this.title = $('.historyBox').data('today') + ' ' + hours + ':' + minutes;
                }
                else
                {
                    this.title = $('.historyBox').data('past') + ' ' + $.datepicker.formatDate($.datepicker.regional[$('.historyBox').data('lang')].dateFormat, titleDate);
                }
            });
        },
        initInterface: function() {
            var self = this;
            var tmpHistory = $('#historyWrap'),
                            slides = tmpHistory.find('.historySlide'),
                            slidesNumber = slides.length;

            if (slidesNumber > 1) {
                slides.each(function(i) {
                    slides[i] = $(this);
                });
                var leftArrow = $('#historyLeft').addClass('disabled').show(),
                    rightArrow = $('#historyRight').show(),
                    activeSlide = 0;

                adjustHistorySlidesMaxHeight(slides[activeSlide]);
                leftArrow.click(function() {
                    if (!leftArrow.hasClass('disabled')) {
                        slides[activeSlide].hide();
                        activeSlide--;
                        slides[activeSlide].show();
                        adjustHistorySlidesMaxHeight(slides[activeSlide]);
                        if (activeSlide == 0)
                            leftArrow.addClass('disabled');
                        if (rightArrow.hasClass('disabled'))
                            rightArrow.removeClass('disabled');
                    }
                });
                rightArrow.click(function() {
                    if (!rightArrow.hasClass('disabled')) {
                        slides[activeSlide].hide();
                        activeSlide++;
                        slides[activeSlide].show();
                        adjustHistorySlidesMaxHeight(slides[activeSlide]);
                        if (activeSlide + 1 == slidesNumber)
                            rightArrow.addClass('disabled');
                        if (leftArrow.hasClass('disabled'))
                            leftArrow.removeClass('disabled');
                    }
                });
            }
        },

        arraySort: function(array, sortFunc) {
            var tmp = [];
            var aSorted = [];
            var oSorted = {};

            for (var k in array) {
                if (array.hasOwnProperty(k)) {
                    tmp.push({key: k, value: array[k]});
                }
            }

            tmp.sort(function(o1, o2) {
                return sortFunc(o1.value, o2.value);
            });

            if (Object.prototype.toString.call(array) === '[object Array]') {
                $.each(tmp, function(index, value) {
                    aSorted.push(value.value);
                });
                return aSorted;
            }

            if (Object.prototype.toString.call(array) === '[object Object]') {
                $.each(tmp, function(index, value) {
                    oSorted[value.key] = value.value;
                });
                return oSorted;
            }
        },
        initClear: function() {
            $(document).on('click', '#clearHistory', function() {
                $.cookie('cookie_history', 'remove', {'expires': -5, 'path': '/'});
                $('#historyWrap').html('');
            });
            this.clearSeted = true;
        }
    },

    FooterNewsletter: {
        config: {
            box: '.newsletterFooterBox',
            email: '#newsletterFooterEmail',
            yes: '#newsletterFooterYes',
            error: '#newsletterFooterError',
            success: '.newsletterSuccess',
            confirmEmail: '#newsletterFooterConfirmEmail', // honeypot
            subscribeUrl: '',
            successText: '',
        },
        boxHtml: '',
        successHtml: '',
        init: function(options) {
            var self = this;
            $.extend(self.config, options);

            self.boxHtml = $(self.config.box).prop('outerHTML');
            self.successHtml = "<div class='newsletterSuccess'>" + self.config.successText + "</div>";

            self.initEvents();
        },
        initEvents: function() {
            var self = this;
            $(document).on('click', self.config.yes, function() {
                // pretplati korisnika na newsletter
                $.ajax({
                    type: 'POST',
                    url: self.config.subscribeUrl,
                    data: 'newsletter[email]=' + $(self.config.email).val() + '&newsletter[confirmEmail]=' + $(self.config.confirmEmail).val(),
                    success: function(data) {
                        if (data !== true) { // greška
                            $(self.config.error).html(data.errors.email);
                            $(self.config.email).addClass('errorField');
                        } else { // uspijeh
                            self.showSuccessMessageFor(3000);
                            ga('send', 'event', 'Naslovnica', 'Newsletter', 'Footer desktop');
                            $.cookie('popup-shown', 'true', { expires: 90, path: '/'}); // 90 dana
                        }
                    }
                });

                return false;
            });

            // čišćenje crvene error boje s input polja na klik
            $(document).on('click', self.config.email, function() {
                $(self.config.email).removeClass('errorField');

                return false;
            });
        },
        showSuccessMessageFor: function(miliseconds) {
            var self = this;

            // unesi email parametar u html prevedene poruke za uspjeh
            var successHtml = self.successHtml.replace('&&1&&', $(self.config.email).val());

            // prikaži poruku za uspjeh
            $(self.config.box).replaceWith(successHtml);

            // nakon milisekundi zamjeni poruku za uspjeh s početnom formom
            setTimeout(function() {
                $(self.config.success).replaceWith(self.boxHtml);
            }, miliseconds);
        }
    },

    ToastManager: {
        _messageEventHandler: () => {},
        config: {
            time: 3000,
        },

        add: function (type, message, time, selectorClass = '') {
            var self = this;
            var toastWrap = $('.toastWrap');

            toastWrap.addClass(selectorClass);

            if (time === undefined) {
                time = calculateToastMessageDuration(message, self.config.time);
            }

            if (isFrame()) {
                const setToastWrapStyleTop = (offset) => {
                    const toastWrapTop = offset < 0 ? 0 : offset;
                    $(toastWrap).css({
                        marginBottom: 0,
                        top: toastWrapTop + 10,
                        bottom: 'auto',
                    });
                };

                // Kako bi izbjegli memory leak uklanjamo prijašnji event listener, inače bi svaki prikaz toast poruke
                // zakačio još jedan handler na 'message' event.
                window.removeEventListener('message', this._messageEventHandler, false);
                this._messageEventHandler = function({ data }) {
                    const dataAsObject = getMessageDataAsObject(data);
                    if (dataAsObject.type === FRAME_OFFSET_CHANGED) {
                        setToastWrapStyleTop(dataAsObject.offset);
                    }
                }

                // Listener za druge događaje zbog kojih bi se mogao mijenjati offset frame-a
                window.addEventListener('message', this._messageEventHandler, false);

                // Za ispravno inicijalno pozicioniranje
                getFrameOffset().then((offset) => {
                    if (offset !== undefined) {
                        setToastWrapStyleTop(offset);
                    }
                });

                // Support za legacy frame skriptu (vac.js)
                parent.postMessage(JSON.stringify({'scrollInfo':true}),'*');
            }
            self.show(type, message, time);
        },

        show: function(type, message, time) {
            var toastWrap = $('.toastWrap');

            $(toastWrap).append('<div class="toastMsg">' + message + '</div>');

            var message = new ToastMessage(type, message, time);
            if (type === 'error') {
                time = 99999;
            }

            message.showMsg(500);
            message.closeToast();
            message.hideAfter(time);
        }
    },

    SimpleSlider: {
        config: {
            selector: '',
            time: 5000,
        },

        createSliderFrom: function(options) {
            var self = this;
            $.extend(self.config, options);
            var slideIndex = 0;
            var time = self.config.time;
            showSlides();

            function showSlides() {
                var i;
                var slides = $(self.config.selector).find('li');

                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                slideIndex++;
                if (slideIndex > slides.length) {
                    slideIndex = 1
                }
                // Može biti undefined zbog pjaxa, npr. kada se prelazi sa naslovnice na pretragu privatnog smještaja
                // bez refresha dolazi do greške u konzoli
                if (typeof slides[slideIndex-1] !== 'undefined') {
                    slides[slideIndex-1].style.display = "inline-block";
                }
                setTimeout(showSlides, time);
            }
        },
    }
};

$(window).on('resize orientationchange', function(e) {
    if ($.datepicker._datepickerShowing) {
        var datepicker = $.datepicker._curInst;
        let dpInput = datepicker.input;
        let dpElem = datepicker.dpDiv;
        dpElem.position({
            my: 'left top',
            of: dpInput,
            at: 'left bottom'
        });
    }
});

$(".searchInputFrom, .searchInputTo").on('click', function (e) {
    if ($.datepicker._datepickerShowing) {
        var datepicker = $.datepicker._curInst;
        let dpInput = datepicker.input;
        let dpElem = datepicker.dpDiv;
        dpElem.position({
            my: 'left top',
            of: dpInput,
            at: 'left bottom'
        });
    }
});

$('#userLanguage li').on('click', function () {
    $('#allLanguages').addClass('active');
});

$('#allLanguages').on('mouseleave', function () {
    $(this).removeClass('active');
});

$(function () {
    if (!$('select').parents('.customSelect').length) {
        $('select').wrap('<div class="customSelect">');
    }

    let $promoCodeData = $('#promoCode');
    if ($promoCodeData.length) {
        promoCodeInit($promoCodeData.data('message'));
    }
});

function adjustHistorySlidesMaxHeight($currentSlide) {
    const initialHeight = 185;
    const $slideItems = $currentSlide.children('a');
    const maxHeight = $slideItems.toArray()
        .map(item => $(item).height())
        .reduce((maxHeight, currentValue) => currentValue > maxHeight ? currentValue : maxHeight, initialHeight);

    $slideItems.height(maxHeight);
}

export default Ad;
