/**
 * @param {object} googlePlacesService
 * @param {object} placesServiceStatus google-ovi statusi za PlacesService
 *
 * @return {function} getPlaceInfo funkcija za dohvaćanje podataka o mjestu
 */
export default function makeGetPlaceInfo(googlePlacesService, placesServiceStatus) {
    /**
     * @param {string} placeIdString lokacija koju je korisnik odabrao
     * @param {function} placeFoundCallback poziva se ako traženo mjesto postoji preko google-ovog PlacesService API-ja
     * @param {function} placeNotFoundCallback poziva se ako mjesto ne postoji preko google-ovog PlacesService API-ja
     */
    function getPlaceInfo(placeIdString, placeFoundCallback, placeNotFoundCallback) {
        if (typeof placeIdString !== 'string' || placeIdString.substr(0, 7) !== 'google_') {
            placeNotFoundCallback();

            return;
        }

        googlePlacesService.getDetails({ placeId: placeIdString.substr(7), fields: ['name', 'geometry'] }, (place, status) => {
            if (status !== placesServiceStatus.OK) {
                placeNotFoundCallback();
            } else {
                placeFoundCallback(place.name, {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                }, place.geometry.viewport);
            }
        });
    }

    return getPlaceInfo;
}
