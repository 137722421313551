export function unconfiguredFormClear($, formId, ignoreSelectorsArray = []) {
    const ignoredSelectors = ignoreSelectorsArray.join(', ');
    $(`#${formId} input`)
        .not('[type=checkbox]')
        .not('[type=radio]')
        .not('[type=submit]')
        .not('[type=reset]')
        .not('[name="fs_set"]')
        .not(ignoredSelectors)
        .val('');

    $(`#${formId} textarea`)
        .not(ignoredSelectors)
        .val('');

    $(`#${formId} input[type="checkbox"]`)
        .not(ignoredSelectors)
        .prop('checked', false);

    $(`#${formId} input[type="radio"]`)
        .not(ignoredSelectors)
        .prop('checked', false);

    $(`#${formId} select`).not(ignoredSelectors).val('');
}

export default function formClear(formId, ignoreSelectorsArray) {
    return unconfiguredFormClear(
        $,
        formId,
        ignoreSelectorsArray,
    );
}
